export enum LayoutType {
  QuestionPage = 'QuestionPage',
  ChapterPage = 'ChapterPage',
  ChapterSummaryPage = 'ChapterSummaryPage',
  WelcomePage = 'WelcomePage',
  SubmitPage = 'SubmitPage',
  DonePage = 'DonePage',
  AboutPage = 'AboutPage',
  SkipPage = 'SkipPage'
}
