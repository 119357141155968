import { Action } from '@ngrx/store';
import {
  Answer,
  LibraryQuestionPreview,
  ModulePreview,
  ModulePreviewResult,
  QuestionFieldPreview,
  Survey,
  SurveyUIState
} from 'app/shared/models';
import { SurveyNavigationBookmark } from 'app/shared/survey-render/models';
import { TargetAction } from '../effects';
import { SurveyUITheme } from 'app/shared/models';
export enum Type {
  INIT_SURVEY_APP = '[SURVEY] INIT',
  LOAD_SURVEY_CONTENT = '[SURVEY] LOAD_SURVEY_CONTENT',
  LOAD_SURVEY_CONTENT_SUCCESS = '[SURVEY] LOAD_SURVEY_CONTENT_SUCCESS',
  LOAD_SURVEY_CONTENT_FAILURE = '[SURVEY] LOAD_SURVEY_CONTENT_FAILURE',
  LOAD_SURVEY_SURVEY_EXPIRED = '[SURVEY] LOAD_SURVEY_SURVEY_EXPIRED',
  LOAD_SURVEY_STATE = '[SURVEY] LOAD_SURVEY_STATE',
  LOAD_ANSWERS_DATABASE = '[SURVEY] LOAD_ANSWERS_DATABASE',
  LOAD_ANSWERS_DATABASE_SUCCESS = '[SURVEY] LOAD_ANSWERS_DATABASE_SUCCESS',
  SAVE_ANSWERS_DATABASE = '[SURVEY] SAVE_ANSWERS_DATABASE',
  SAVE_ANSWERS_DATABASE_SUCCESS = '[SURVEY] SAVE_ANSWERS_DATABASE_SUCCESS',
  SAVE_ANSWERS_DATABASE_FAILURE = '[SURVEY] SAVE_ANSWERS_DATABASE_FAILURE',
  START_SURVEY = '[SURVEY] START_SURVEY',
  SUBMIT_SURVEY = '[SURVEY] SUBMIT_SURVEY',
  SUBMIT_SURVEY_SUCCESS = '[SURVEY] SUBMIT_SURVEY_SUCCESS',
  SUBMIT_SURVEY_FAILURE = '[SURVEY] SUBMIT_SURVEY_FAILURE',
  NEXT_PAGE = '[SURVEY] NEXT_PAGE',
  PREVIOUS_PAGE = '[SURVEY] PREVIOUS_PAGE',
  REGISTER_ANSWER = '[SURVEY] REGISTER_ANSWER',
  REMOVE_ANSWER = '[SURVEY] REMOVE_ANSWER',
  SET_FAST_FORWARD_PAGE = '[SURVEY] SET_FAST_FORWARD_PAGE',
  FAST_FORWARD_PAGE = '[SURVEY] FAST_FORWARD_PAGE',
  GO_TO_PAGE = '[SURVEY] GO_TO_PAGE',
  GO_TO_PAGE_SUCCESS = '[SURVEY] GO_TO_PAGE_SUCCESS',
  GO_TO_PAGE_FAILURE = '[SURVEY] GO_TO_PAGE_FAILURE',
  SKIP_PAGE = '[SURVEY] SKIP_PAGE',
  CANCEL_SKIP = '[SURVEY] CANCEL_SKIP',
  LOAD_PREVIEW = '[SURVEY] LOAD_PREVIEW',
  LOAD_QUESTION_PREVIEW = '[SURVEY] LOAD_QUESTION_PREVIEW',
  LOAD_QUESTION_FIELD_PREVIEW = '[SURVEY] LOAD_QUESTION_FIELD_PREVIEW',
  LOAD_PREVIEW_SUCCESS = '[SURVEY] LOAD_PREVIEW_SUCCESS',
  CLEAR_STATE = '[SURVEY] CLEAR_STATE',
  SET_QUESTION_VISIBILITY = '[SURVEY] SET_QUESTION_VISIBILITY',
  SET_CONTENT_VISIBILITY = '[SURVEY] SET_CONTENT_VISIBILITY',
  SET_CHAPTER_VISIBILITY = '[SURVEY] SET_CHAPTER VISIBILITY',
  LOAD_CHAPTER_SUMMARY = '[SURVEY] LOAD_CHAPTER_SUMMARY',
  SETUP_SURVEY_CONTEXT = '[SURVEY] SETUP_SURVEY_CONTEXT',
  PREVIEW_THEME_SELECTED = '[SURVEY] PREVIEW_THEME_SELECTED',
}

export class InitSurveyApp implements Action {
  readonly type = Type.INIT_SURVEY_APP;
}
export class LoadSurveyContent implements Action {
  readonly type = Type.LOAD_SURVEY_CONTENT;
  constructor(public languageId?: number) { }
}
export class LoadSurveyContentSuccess implements Action {
  readonly type = Type.LOAD_SURVEY_CONTENT_SUCCESS;
  constructor(public payload: Survey) { }
}

export class LoadSurveySurveyExpired implements Action {
  readonly type = Type.LOAD_SURVEY_SURVEY_EXPIRED;
  constructor() { }
}

export class LoadSurveyContentFailure implements Action {
  readonly type = Type.LOAD_SURVEY_CONTENT_FAILURE;
  constructor() { }
}
export class LoadSurveyState implements Action {
  readonly type = Type.LOAD_SURVEY_STATE;
  constructor(public payload: SurveyUIState) { }
}
export class LoadSurveyAnswersFromDatabase implements Action {
  readonly type = Type.LOAD_ANSWERS_DATABASE;
  constructor(public payload: Answer[]) { }
}
export class LoadSurveyAnswersFromDatabaseSuccess implements Action {
  readonly type = Type.LOAD_ANSWERS_DATABASE_SUCCESS;
  constructor(public payload: Answer[]) { }
}
export class StartSurvey implements Action {
  readonly type = Type.START_SURVEY;
  constructor() { }
}
export class SubmitSurvey implements Action {
  readonly type = Type.SUBMIT_SURVEY;
  constructor() { }
}
export class SubmitSurveySuccess implements Action {
  readonly type = Type.SUBMIT_SURVEY_SUCCESS;
  constructor() { }
}
export class SubmitSurveyFailure implements Action {
  readonly type = Type.SUBMIT_SURVEY_FAILURE;
  constructor() { }
}

export class NextPage implements Action {
  readonly type = Type.NEXT_PAGE;
  constructor() { }
}

export class PreviousPage implements Action {
  readonly type = Type.PREVIOUS_PAGE;
  constructor() { }
}

export class RegisterAnswer implements Action {
  readonly type = Type.REGISTER_ANSWER;
  constructor(public payload: Answer) { }
}

export class RemoveAnswer implements Action {
  readonly type = Type.REMOVE_ANSWER;
  constructor(public payload: Pick<Answer, 'questionId'>) { }
}

export class SetFastForwardPage implements Action {
  readonly type = Type.SET_FAST_FORWARD_PAGE;
}
export class FastForwardPage implements Action {
  readonly type = Type.FAST_FORWARD_PAGE;
  constructor() { }
}
export class GoToPage implements Action {
  readonly type = Type.GO_TO_PAGE;
  constructor(public payload: SurveyNavigationBookmark) { }
}
export class GoToPageSuccess implements Action {
  readonly type = Type.GO_TO_PAGE_SUCCESS;
  constructor(public payload: SurveyNavigationBookmark) { }
}
export class GoToPageFailure implements Action {
  readonly type = Type.GO_TO_PAGE_FAILURE;
  constructor(public err?: any) { }
}
export class LoadPreview implements Action {
  readonly type = Type.LOAD_PREVIEW;
  constructor(public payload: ModulePreview) { }
}
export class LoadQuestionPreview implements Action {
  readonly type = Type.LOAD_QUESTION_PREVIEW;
  constructor(public payload: LibraryQuestionPreview) { }
}
export class LoadQuestionFieldPreview implements Action {
  readonly type = Type.LOAD_QUESTION_FIELD_PREVIEW;
  constructor(public payload: QuestionFieldPreview) { }
}

export class LoadPreviewSuccess implements Action {
  readonly type = Type.LOAD_PREVIEW_SUCCESS;
  constructor(public payload: ModulePreviewResult) { }
}
export class ClearState implements Action {
  readonly type = Type.CLEAR_STATE;
  constructor() { }
}
export class SetQuestionVisibility implements Action {
  readonly type = Type.SET_QUESTION_VISIBILITY;
  constructor(public payload: TargetAction[]) { }
}

export class SetContentVisibility implements Action {
  readonly type = Type.SET_CONTENT_VISIBILITY;
  constructor(public payload: { contentId: number, visible: boolean }) { }
}

export class SetChapterVisibility implements Action {
  readonly type = Type.SET_CHAPTER_VISIBILITY;
  constructor(public payload: { pageGroupKey: string, visible: boolean }) { }
}
export class LoadChapterSummary implements Action {
  readonly type = Type.LOAD_CHAPTER_SUMMARY;
  constructor(public payload: any) { }
}

export class SkipPage implements Action {
  readonly type = Type.SKIP_PAGE;
  constructor(public payload: any) { }
}

export class CancelSkip implements Action {
  readonly type = Type.CANCEL_SKIP;
  constructor() { }
}

export class SaveAnswersDatabase implements Action {
  readonly type = Type.SAVE_ANSWERS_DATABASE;
  constructor(public payload: any) { }
}

export class SaveAnswersDatabaseSuccess implements Action {
  readonly type = Type.SAVE_ANSWERS_DATABASE_SUCCESS;
  constructor() { }
}
export class SaveAnswersDatabaseFailure implements Action {
  readonly type = Type.SAVE_ANSWERS_DATABASE_FAILURE;
  constructor(public error: any) { }
}

export class SetupSurveyContext implements Action {
  readonly type = Type.SETUP_SURVEY_CONTEXT;
}

export class PreviewThemeSelected implements Action {
  readonly type = Type.PREVIEW_THEME_SELECTED;
  constructor(public payload: SurveyUITheme) { }
}

export type Actions =
  InitSurveyApp | LoadSurveyContent | LoadSurveyContentSuccess | LoadSurveyContentFailure | LoadSurveyState
  | StartSurvey | SubmitSurvey | SubmitSurveySuccess | SubmitSurveyFailure
  | NextPage | PreviousPage | SkipPage | CancelSkip | RegisterAnswer | FastForwardPage
  | GoToPage | GoToPageSuccess | GoToPageFailure
  | LoadPreview |LoadQuestionFieldPreview | LoadPreviewSuccess | ClearState
  | SetQuestionVisibility | SetContentVisibility | SetChapterVisibility | LoadChapterSummary | SetFastForwardPage
  | LoadSurveyAnswersFromDatabase | LoadSurveyAnswersFromDatabaseSuccess
  | RemoveAnswer
  | SaveAnswersDatabase | SaveAnswersDatabaseSuccess | SaveAnswersDatabaseFailure
  | LoadSurveySurveyExpired | SetupSurveyContext
  | PreviewThemeSelected;
